<template>
  <div class="evaluatePage">
    <div class="evaluate-header">
      <p>
        <strong>评价</strong>
      </p>
    </div>

    <div class="evaluate-main">
      <el-empty v-if="dataLists.length==0" description="暂无评价"/>
      <section v-for="(dataList,idx) in dataLists" :key="idx">
        <div id="" class="ly-1">
          <h1>{{ dataList.task_name }}</h1>
          <div id="" class="ly-1-star">
            <el-rate v-model="dataList.score" :disabled="true" :colors="colors"/>
            <span class="grade">{{ dataList.score }}.0</span>
          </div>
        </div>
        <div id="" class="ly-2-comment">
          <h2>{{ dataList.content }}</h2>
        </div>
        <div id="" class="ly-3-subTitle">
          <span v-for="(sub,subIndex) in dataList.task_skills" :key="subIndex">{{ sub.name }}</span>
        </div>
        <div id="" class="ly-4-person">
          <el-avatar :size="40" :src="dataList.user_avatar"/>
          <p class="email">{{ dataList.user_name }}</p>
          <p class="time">{{ dataList.created_at }}</p>
          <el-button type="primary" :class="{ response: true, gray: dataList.is_reply=='y' }" size="mini"
                     @click="reply(idx)">
            {{ dataList.is_reply == 'y' ? '已回复' : '回复' }}
          </el-button>
        </div>
        <div id="" class="ly-5-answer" v-if="dataList.is_reply=='y'">
          <el-avatar :size="40" v-if="dataList.reply!=null" :src="dataList.reply.user_avatar"/>
          <div v-if="dataList.reply!=null" class="answer-name">
            <div>
              <span>{{ dataList.reply.user_name }}</span>
              <span>{{ dataList.reply.created_at }}</span>
            </div>
            <p>{{ dataList.reply.content }}</p>
          </div>
        </div>
        <div id="" class="ly-5-print" v-if="dataList.open_reply">
          <el-input class="ly-5-textarea" type="textarea" rows="" v-model="postEvaluate.content" cols=""
                    placeholder="请输入回复内容"/>
          <div id="" class="buttons">
            <el-button class="cancelBtn" size="mini" @click="dataList.open_reply=false">取消</el-button>

            <el-popconfirm
                @confirm="replyThisEvaluate( idx, dataList.id )"
                title="评价回复一旦提交就无法修改，请慎重!"
            >
              <button slot="reference" style="
                font-size: 12px;
                margin: 12px;
                background-color: #00A2E6;
                height: 28px;
                width: 100px;
                color: #fff;
                border-radius: 3px !important;
                line-height: 28px;" type="primary">提交
              </button>
            </el-popconfirm>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {getEvaluate, toEvaluate} from "@/api/user";

export default {
  name: "index",
  data() {
    return {
      postEvaluate: {
        content: ''
      },
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      dataLists: [],
      pageParams: {page: 1, page_size: 10},
      pages: {
        page: 1,
        page_size: 10,
        total: 0,
        pge_num: 1
      }
    }
  },
  created() {
    this.loadEvaluateList()
  },
  methods: {
    async loadEvaluateList() {
      const res = await getEvaluate({type: 1, sort_type: 1})
      if (res.statusCode == 200) {
        let theData = res.data.list
        theData.map(item => {
          item.open_reply = false
        })
        this.dataLists = theData
      }
    },

    reply(index) {
      if (this.dataLists[index].is_reply == 'n') {
        this.$set(this.dataLists[index], 'open_reply', true)
      }
    },
    async replyThisEvaluate(index, evaluateId) {
      this.postEvaluate.evaluate_id = evaluateId
      if (this.postEvaluate.content == '') {
        this.$message.warning('请输入您的回复再提交!');
        return false;
      }
      const res = await toEvaluate(this.postEvaluate);
      if (res.statusCode == 200) {
        let currentUser = this.$store.getters["useUserStore/getUserInfo"]
        this.$set(this.dataLists[index], 'open_reply', false)
        this.$set(this.dataLists[index], 'is_reply', 'y')
        this.$set(this.dataLists[index], 'reply', {
          user_name: currentUser.name,
          user_avatar: currentUser.avatar,
          content: this.postEvaluate.content
        })
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.evaluatePage {
  background-color: #fff;
  min-height: 800px;
}

.evaluate-header {
  height: 76px;
  border-bottom: 1px solid #b5b5b5;
  display: flex;

  p {
    font-size: 18px;
    margin: 40px;
  }
}

.evaluate-main {
  margin-top: 20px;
  background-color: #fff;
  padding: 0 40px;

  & > header {
    font-size: 20px;
    border-bottom: 1px solid #e7e7e7;
    height: 80px;
    line-height: 80px;
  }

  & > section {
    margin-top: 20px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 24px;

    .ly-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      h1 {
        font-size: 16px;
        font-weight: 500;
      }
      .grade {
        font-size: 12px;
      }
    }

    .ly-2-comment {
      font-size: 14px;
      color: #505050;
    }

    .ly-3-subTitle {
      margin-top: 15px;
      color: #00A2E6;
      font-size: 14px;

      span {
        margin: 0 12px;
        position: relative;

        &:not(:last-of-type)::after {
          content: '';
          position: absolute;
          width: 2px;
          background-color: #00A2E6;
          right: -12px;
          top: 4px;
          bottom: 2px;
        }
      }

      span:first-child {
        margin-left: 0px !important;
      }
    }

    .ly-4-person {
      display: flex;
      position: relative;
      margin-top: 15px;
      align-items: center;

      .email {
        margin-left: 12px;
      }

      .time {
        margin-left: 22px;
        font-size: 12px;
        color: #575757;
      }

      .response {
        position: absolute;
        right: 0;
        font-size: 12px;
        width: 120px;
        text-align: center;
        background-color: #00A2E6;
        color: #fff;
      }

      .response.gray {
        background-color: #B5B5B5;
        border-color: #B5B5B5;
      }
    }

    .ly-5-answer {
      background-color: #F8F8F8;
      display: flex;
      padding: 12px;
      margin-top: 12px;
      align-items: center;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 8%), 0 2px 4px 0 rgba(0, 0, 0, 8%);

      & > img {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
      }

      .answer-name {
        display: flex;
        margin-left: 20px;
        flex-direction: column;
        flex-grow: 1;

        & > div {
          border-bottom: 1px solid #e7e7e7;
          padding: 10px 0;
          width: 100%;

          span:last-of-type {
            margin-top: 12px;
            font-size: 12px;
            color: #575757;
            margin-left: 40px;
          }
        }

        & > p {
          padding: 10px 0;
          color: #575757;
          font-size: 12px;

        }
      }
    }

    .ly-5-print {
      width: 100%;
      margin-top: 12px;
      text-align: center;

      & > textarea {
        width: 100%;
        height: 60px;
      }

      & > .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 12px;

        & > button {
          width: 100px;
        }

        .cancelBtn {
          background-color: #B5B5B5;
          border-color: #B5B5B5;
          color: #fff;
        }
      }
    }

    /deep/ .el-textarea__inner {
      color: #3A3939 !important;
    }
  }
}
</style>
